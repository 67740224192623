import React, { useEffect, useState, useRef } from 'react';
// import * as fabric from 'fabric'; // v6
import { fabric } from 'fabric'; // v5

export default function ImageMaker() {
    // our canvas element
    const canvasEl = useRef();
    let fabricCanvas = null;

    // canvas dimensions
    const canvasWidth = 600;
    const canvasHeight = 600;
    
    // all images to choose from
    const images = Array.from({length: 10}, (_, i) => i + 1);
    const imagesPaths = images.map(i => `images/${i}.jpg`);
    
    // the text on the image
    let text = "freebasing based bicycles";

    // reference for our save button
    const saveRef = useRef();

    // our background image object
    let backgroundImageElement = null;
    
    // our text object that goes on top of the background
    let textElement = null;

    // how wide should our canvas be?
    const getCanvasWidth = () => {
        return (window.innerWidth < 600 ? window.innerWidth - 26 : 600);
    };

    const getDevicePixelRatio = () => {
        return window.devicePixelRatio || 1;
    }

    // run when mounted
    useEffect(() => {
        // initialize our canvas
        if (fabricCanvas === null) {
            const canvas = new fabric.Canvas(canvasEl.current, {});

            canvas.textRendering = 'optimizeLegibility';
            canvas.setWidth(canvasWidth);
            canvas.setHeight(canvasHeight);

            // access the underlying canvas element of the Fabric.js canvas
            // to disable image smoothing for different browsers
            const ctx = canvas.lowerCanvasEl.getContext('2d');

            ctx.webkitImageSmoothingEnabled = false;
            ctx.mozImageSmoothingEnabled = false;
            ctx.imageSmoothingEnabled = false;

            // save the reference to our canvas object
            fabricCanvas = canvas;

            // add our text object
            textElement = new fabric.Text(text, {
                left: 15,
                top: 15,
                id: 'text',
                fontFamily: 'Arial',
                fontSize: 20,
            });

            // set the text, add it to the canvas, move it to the front, and render the canvas
            textElement.set('text', text);
            fabricCanvas.add(textElement);
            textElement.bringToFront();
            fabricCanvas.renderAll();
        }

        // when loaded, set the default image
        const handleInitialLoad = () => {
            const initialWidth = getCanvasWidth();
            const initialHeight = getCanvasWidth();

            fabricCanvas.setDimensions({ width: initialWidth, height: initialHeight });

            handleImageFromUrl(imagesPaths[0]);
        };

        // when the page is resized resize the canvas and scale the background image
        const handleResize = () => {
            const newDimension = getCanvasWidth();

            fabricCanvas.setDimensions({ width: newDimension, height: newDimension });
            fabricCanvas.renderAll();

            if (backgroundImageElement) {
                const scaleFactorX = newDimension / backgroundImageElement.width;
                const scaleFactorY = newDimension / backgroundImageElement.height;
                const scaleFactor = Math.min(scaleFactorX, scaleFactorY);

                backgroundImageElement.scale(scaleFactor);
            }

            fabricCanvas.renderAll();
        };

        // change the canvas size then load the first image
        handleResize();
        handleInitialLoad();

        // window resize event to change canvas size
        window.addEventListener('resize', handleResize);

        // dispose of our canvas and resize event
        return () => {
            try { fabricCanvas.dispose(); } catch(e) {}
            fabricCanvas = null;
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    // load a new base image
    const handleImageFromUrl = function (url) {
        const canvasWidth = getCanvasWidth();
        const canvasHeight = getCanvasWidth();

        fabric.util.loadImage(url, function (Img) {
            // loop through all the layers to delete the current background image
            const objects = fabricCanvas.getObjects();

            for (var i in objects) {
                if (objects[i].id === 'back') {
                    fabricCanvas.remove(objects[i]);
                }
            }

            // re render the canvas without the background image
            fabricCanvas.renderAll();

            // create our new background image, add it to the canvas,
            // send the layer to the back, and render everything
            backgroundImageElement = new fabric.Image(Img, {
                scaleX: canvasWidth / Img.width,
                scaleY: canvasHeight / Img.height,
                id: 'back',
                selectable: 0,
            });

            fabricCanvas.add(backgroundImageElement);
            backgroundImageElement.sendToBack();
            fabricCanvas.renderAll();
        });
    };

    // save the current canvas as an image
    const saveImage = (e) => {
        // 1
        // saveRef.current.href = fabricCanvas.toDataURL({
        //     format: 'png',
        //     // quality: 0.8,
        // });

        // saveRef.current.download = 'fbbb.png'

        // 2
        // const link = document.createElement('a');
        // link.href = fabricCanvas.toDataURL({
        //     format: 'png',
        //     // quality: 0.8,
        // });
        // link.download = 'fbbb.png';
        // link.click();

        // Convert canvas data to a data URL
        const dataUrl = fabricCanvas.toDataURL({
            // format: 'image/jpeg',
            // quality: 1,

            format: 'png',
            multiplier: 2,
        });

        // Convert data URL to a blob
        fetch(dataUrl)
            .then(res => res.blob())
            .then(blob => {
                // Create a new URL for the blob
                const blobUrl = URL.createObjectURL(blob);

                // Create an anchor element to open the blob URL in a new tab
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'fbbb.png';

                // Simulate a click on the anchor element
                link.click();

                // Clean up by revoking the blob URL after some time
                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl);
                }, 1000); // Adjust as needed
            });
    }

    return (
        <div id="container">
            <div id="tool">
                <img id="logo" src="logo.png" alt="freebasing based bicycles" />
                <div id="builder">
                    <textarea defaultValue={text} onChange={(e) => {
                        text = e.target.value;
                        
                        if (textElement !== null) {
                            textElement.set('text', text);
                            fabricCanvas.renderAll();
                        }
                    }} />
                    <div id="canvasAndLink">
                        <div id="link">
                            <a id="linkClickable" ref={saveRef} href="#" onClick={(e) => saveImage(e)}>
                                <img id="linkSave" src="save.png" alt="Save" />
                            </a>
                        </div>
                        <div>
                            <canvas id="imageCanvas" width="300" height="300" ref={canvasEl} />
                        </div>
                    </div>
                    <div id="myTokensContainer">
                        <div id="myTokens">
                            {images.map((imageId, index) => {
                                return (
                                    <img
                                        key={index}
                                        className={`token`}
                                        src={`images/${imageId}.jpg`}
                                        width="100"
                                        height="100"
                                        onClick={() => {
                                            handleImageFromUrl(`images/${imageId}.jpg`);
                                        }} />
                                );
                            })}
                        </div>
                    </div>
                    <div id="by">Made for <a href="https://twitter.com/Mnewbis" target="_blank" rel="noreferrer">@Mnewbis</a> with love, by <a href="https://twitter.com/metazord_eth" target="_blank" rel="noreferrer">@metazord_eth</a></div>
                </div>
            </div>
        </div>
    );
}